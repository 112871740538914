.payments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.payments svg {
  margin-right: 22px;
  margin-bottom: 10px;
}

@media (max-width: 1000px) {
  .payments {
    justify-content: center;
  }
  .payments svg {
    margin: 0 12px;
    margin-bottom: 24px;
  }
}
