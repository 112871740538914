.catalogSidebar {
  width: 381px;
  margin-right: 24px;
  height: fit-content;
}

.catalogSidebarList {
  padding-inline-start: 15px;
}

.catalogSidebarItem {
  margin-bottom: 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4d6e7d;
}

.catalogSidebarLink {
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
}

.catalogSidebarLink:hover {
  text-decoration: underline;
}

@media (max-width: 1000px) {
  .catalogSidebar {
    width: 100%;
    height: 100%;
  }
}
