.pageHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageWrap {
  margin: 64px 0;
}

.pagePagination {
  margin-bottom: 70px;
}
