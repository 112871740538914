.card {
  position: relative;
  width: 100%;
  height: 100%;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
}

.cardLink {
  text-decoration: none;
  color: #303030;
}

.cardImgLink {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  background: #ffffff;
}

.dropdownRow:not(:last-of-type) {
  margin-bottom: 8px;
}

.dropdownRow {
  display: flex;
  justify-content: space-between;
}

.dropdownRow .dropdownRowTitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

.dropdownRow .dropdownRowVal,
.dropdownRow .dropdownRowYear {
  font-size: 14px;
  line-height: 16px;
  color: #5c5c5c;
}

.dropdownRowYear {
  margin-left: 10px;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
