.section {
  margin-bottom: 88px;
}

.sectionHead {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.5;
  color: #5c5c5c;
}

.mainCard {
  width: 517px;
  max-width: 33%;
  max-height: 787px;
}

.additional {
  width: calc(100% - 517px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.additionalCards {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}

.smallCard {
  width: calc(25% - 20px);
  height: 100%;
}

.smallCard:not(:last-of-type) {
  margin-right: 24px;
}

.slider {
  width: 100%;
  flex: 1 1 auto;
}

.trackX {
  max-height: 6px;
  background: #d6dde0 !important;
  border-radius: 3px !important;
  left: 0 !important;
  width: 100% !important;
}

.thumbX {
  background: #0b486b !important;
  border-radius: 3px !important;
}

@media (max-width: 1648px) {
  .sectionHead {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .mainCard {
    width: 490px;
    max-width: 31.5%;
    max-height: 704px;
  }

  .additional {
    width: calc(100% - 31.5% - 24px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 1000px) {
  .section {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 48px;
  }

  .sectionHead {
    justify-content: center;
  }

  .additional {
    width: 100%;
  }

  .smallCard {
    max-width: 131px;
    min-width: 131px;
    overflow: hidden;
    height: fit-content;
  }

  .additionalCards {
    height: fit-content;
    padding: 24px 0px 0px 16px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .mainCard {
    position: relative;
    padding-bottom: 24px;
    margin: 0 auto;
    max-width: 100%;
    max-height: fit-content;
  }

  .mainCardWrap {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .trackX {
    width: calc(100% - 16px) !important;
  }
}

@media (max-width: 640px) {
  .additionalCards,
  .sectionHead {
    justify-content: initial;
    overflow: visible;
  }

  .additionalCards {
    padding: 24px 0 16px 0;
  }

  .additional {
    width: calc(100% + 16px);
    margin-right: -16px;
  }
}
