.resultsHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultsString {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.4);
}

.resultsWrap {
  margin: 64px 0;
}

.resultsPagination {
  margin-bottom: 70px;
}

@media (max-width: 639px) {
  .resultsHead {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}
