.notification {
  height: 24px;
  position: relative;
}

.dropdown {
  position: relative;
  padding: 16px 20px 0 16px;
}

button.dropdownCloseBtn {
  position: absolute;
  right: 12px;
}

.dropdownTitle {
  width: 100%;
  padding-right: 15px;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 15px;
  line-height: 146%;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.87);
}

.dropdownItemImg {
  width: 48px;
  margin-right: 32px;
}

.dropdownItemImg img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.dropdownItem {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  padding-right: 45px;
}

.dropdownItemArrowLink {
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
}

.dropdownInfo {
  flex-grow: 1;
}

.dropdownInfoHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.dropdownInfoHeadName {
  margin-right: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0b486b;
  text-decoration: none;
}

.dropdownInfoHeadDate {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}

.dropdownInfoRow {
  display: flex;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #303030;
}

.dropdownInfoRowName {
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 70px;
  color: rgba(0, 0, 0, 0.4);
}
