.copyright {
  background-color: #252c31;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright a {
  color: #ffffff;
  margin-left: 10px;
}

@media (max-width: 360px) {
  .copyright {
    display: flex;
    flex-direction: column;
  }

  .copyright a {
    margin-left: 0px;
    margin-top: 10px;
  }
}
