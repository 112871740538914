.homePage {
  padding-top: 64px;
  padding-bottom: 50px;
}

@media (max-width: 1000px) {
  .homePage {
    padding-top: 40px;
    padding-bottom: 0;
  }
}
