.mainWrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding-top: 56px;
}

@media (min-width: 1920px) {
  .main {
    padding: 56px 0px 0px 0px;
  }
}

@media (max-width: 1655px) {
  .main {
    padding: 56px 24px 0px 24px;
  }
}

@media (max-width: 999.9px) {
  .main {
    padding: 56px 16px 0px 16px;
  }
}

@media (max-width: 639px) {
  .main {
    padding: 120px 16px 0px 16px;
  }
}
