.allBooksWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(247px, 247px));
  gap: 64px 0px;
  width: 100%;
  justify-content: space-around;
}

.notFound {
  width: 100%;
  text-align: center;
  padding: 25px 0;
}

@media (max-width: 639px) {
  .allBooksWrapper {
    grid-template-columns: repeat(auto-fill, minmax(328px, 328px));
    gap: 24px 0;
    justify-content: center;
  }
}

@media (max-width: 328px) {
  .allBooksWrapper {
    grid-template-columns: repeat(auto-fill, minmax(100%, 100%));
  }
}
