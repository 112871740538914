.sectionBorder {
  position: relative;
  width: 100%;
  padding: 24px 0 24px 24px;
  background: #ffffff;
  border: 1px solid #d6dde0;
  margin-bottom: 64px;
  overflow: hidden;
}

.sectionHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-right: 24px;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.smallCard {
  margin-right: 24px;
}

.sectionLink {
  text-decoration: none;
  color: #0b486b;
}

.sectionLinkInner {
  display: flex;
  align-items: center;
}

.sectionScroll {
  position: relative;
  display: flex;
  padding-right: 24px;
  padding-bottom: 24px;
}

.trackX {
  max-height: 6px;
  background: #d6dde0 !important;
  border-radius: 3px !important;
  left: 0 !important;
  bottom: 4px !important;
  width: calc(100% - 24px) !important;
}

.thumbX {
  background: #0b486b !important;
  border-radius: 3px !important;
}

.sectionLinkMobile {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .sectionBorder {
    width: calc(100% + 16px);
    margin: 0 -16px 48px 0;
    border: none;
    border-bottom: 1px solid #d6dde0;
    padding: 0 0 24px 0;
  }

  .sectionLink {
    display: none;
  }

  .sectionLinkMobile {
    display: block;
    width: fit-content;
    margin: 40px auto 16px auto;
  }

  .trackX {
    width: calc(100% - 16px) !important;
  }
}
