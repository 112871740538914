@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.eot");
  src: url("./assets/fonts/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-Regular.woff") format("woff"),
    url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.page-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 53px;
  letter-spacing: 0em;
  color: #303030;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #d6dde0;
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0b486b;
  border-radius: 3px;
}

* {
  scrollbar-color: #0b486b #d6dde0;
  scrollbar-width: thin;
}

.ScrollbarsCustom-Scroller {
  overflow: hidden !important;
}
@media (max-width: 1000px) {
  .page-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
  }
}
