.bookPage {
  padding-bottom: 16px;
}

.bookPageWrap {
  width: 100%;
  display: flex;
  margin-bottom: 64px;
}

.bookPageImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 381px;
  min-width: 381px;
  height: 580px;
  max-height: 580px;
  margin-right: 48px;
}

.bookPageImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bookPageInfoBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bookPageHead {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.bookPageShare {
  display: flex;
  line-height: 1.5;
}

.bookPageHead .bookPageTitle {
  margin-right: 15px;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.5;
  color: #303030;
}

.bookPageInfo {
  display: flex;
  flex-direction: column;
  width: fit-content;
  flex-grow: 1;
}

.bookPageInfoRow {
  display: flex;
}

.bookPageInfoRow:not(:last-of-type) {
  margin-bottom: 16px;
}

.dropdownRowName {
  min-width: 120px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.4);
}

.dropdownRowVal {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
}

.bookPageInfoBtns {
  margin-top: 48px;
  margin-bottom: 34px;
  display: flex;
}

.btnPrice {
  min-width: 217px;
  margin-right: 24px;
}

.bookPageInfoPaymants {
  margin-bottom: 29px;
}

.bookPageInfoDescr {
  max-width: 629px;
  margin-bottom: auto;
}

.bookPageInfoDescrTitle {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
}

.bookPageInfoDescr .bookPageInfoDescrText {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
}

.bookPageInfoDuration {
  margin-top: 70px;
}

.link {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .bookPageInfoDuration,
  .bookPageInfoDescr,
  .bookPageInfoPaymants,
  .bookPageInfoRows,
  .bookPageHead {
    position: relative;
  }

  .bookPageInfoPaymants::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .bookPageInfoDuration::after,
  .bookPageInfoDescr::after,
  .bookPageInfoPaymants::after,
  .bookPageInfoRows::after,
  .bookPageHead::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .bookPageHead {
    padding: 24px 0;
    margin-bottom: 0;
  }

  .bookPageHead .bookPageTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .bookPageInfoRows {
    padding: 16px 0;
  }

  .bookPageShare {
    min-height: 122px;
    max-width: 300px;
    margin: 0 auto;
    padding: 24px 0 20px 0;
  }

  .bookPageInfoBtns {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% - 48px);
    margin: 0;
    flex-direction: column;
    width: fit-content;
    z-index: 200;
  }

  .btnPrice {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .btnWishlist {
    background: #ffffff;
    border-radius: 8px;
  }

  .bookPageInfoPaymants {
    margin-bottom: 0;
    padding-top: 24px;
  }

  .bookPageInfoDescr {
    padding: 24px 0;
    margin-bottom: 0;
  }

  .bookPageInfoDuration {
    margin-top: 0;
    padding: 24px 0;
  }
}

@media (max-width: 767px) {
  .bookPageInfoPaymants::before {
    left: -16px;
    width: calc(100% + 32px);
  }

  .bookPageInfoDuration::after,
  .bookPageInfoDescr::after,
  .bookPageInfoPaymants::after {
    left: -16px;
    width: calc(100% + 32px);
  }

  .bookPageWrap {
    margin-bottom: 24px;
    flex-direction: column;
  }

  .bookPageInfo {
    width: 100%;
  }

  .bookPageInfoDescr {
    max-width: 100%;
  }

  .bookPageImg {
    min-width: auto;
    max-width: 100%;
    height: auto;
    margin-right: 0;
  }
}
