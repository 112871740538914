.share {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shareContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.share .shareTitle {
  margin-right: 22px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.4);
}

.shareItem {
  margin-right: 23px;
}

.shareItem a {
  display: flex;
  align-items: center;
}
