button.menuToggler:hover {
  background-color: transparent;
}

button.menuToggler {
  min-width: 24px;
  width: 24px;
  height: 24px;
  color: #ffffff;
  text-indent: -9999px;
  font-size: 0;
  padding: 0px;
  z-index: 1;
}

button.menuToggler svg {
  margin-right: 0px;
}

button.buttonClose {
  padding: 0px;
  min-width: 14px;
  margin-right: 30px;
}

button.buttonClose:hover {
  background-color: transparent;
}

.menuContainer {
  display: flex;
  white-space: nowrap;
}

.menuContainer a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  margin-right: 40px;
}

.menuContainer a:last-child {
  margin-right: 0px;
}

.menuContainer a:hover {
  text-decoration: underline;
}

.logoImg,
.logoImg svg {
  width: 141px;
  height: 30px;
}

.menuRightIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 136px;
  width: 100%;
}

.shelfHight {
  height: 18px;
}

.searchContainer {
  background-color: #ffffff;
  height: 64px;
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  align-items: center;
}

.logoSidebar {
  width: 141px;
  height: 30px;
}

.listItemLink {
  padding: 24px 0px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-decoration: none;
  color: #303030;
  display: flex;
  align-items: center;
}

.listItemLink svg {
  margin-right: 6px;
}

.contactsPosition {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.socialContainer {
  width: 232px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.socialContent a {
  display: flex;
  align-items: center;
  height: 24px;
}

.infoContainer {
  display: flex;
  align-items: center;
}

.infoContainer a {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: none;
  color: #4d6e7d;
  margin-left: 15px;
}

.infoContainer a:hover {
  text-decoration: underline;
}

@media (min-width: 1920px) {
  .menuContainer a {
    margin-right: 64px;
  }

  .menuContainer {
    margin: 0px 40px;
  }
}

@media (min-width: 1000px) and (max-width: 1919px) {
  .menuContainer a {
    margin-right: 24px;
  }

  .menuContainer {
    margin: 0px 40px;
  }
}

@media (min-width: 1000px) {
  button.menuToggler {
    display: none;
  }

  .logoImg,
  .logoImg svg {
    width: 184px;
    height: 40px;
  }
}

@media (max-width: 999.9px) {
  .menuContainer {
    display: none;
  }

  .menuRightIcon {
    max-width: 112px;
  }

  .logoImg {
    margin-left: 16px;
  }
}

@media (max-width: 639px) {
  .logoImg {
    flex-grow: 1;
  }
}
