.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 49px;
}

.link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #0b486b;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.alignCenter button {
  min-width: 139px;
}

.tip {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #00000066;
  margin: 20px 0px 16px 0px;
}

.agreeDescription {
  font-size: 12px;
}

.agreeDescription a {
  color: #0b486b;
}

.fullNameWrapper {
  display: flex;
  justify-content: space-between;
}

.fullNameWrapper > div {
  margin-right: 20px;
}

.formHr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px 0px 20px 0px;
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.btnWrapper button {
  min-width: 139px;
}

.minutemailWrapper {
  display: flex;
  margin-bottom: 48px;
}

.minutemailWrapper svg {
  min-width: 64px;
}

.minutemailWrapper .description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  margin-left: 20px;
  color: #303030;
}

.hrFormsPassword {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

@media (max-width: 639px) {
  .fullNameWrapper {
    display: flex;
    flex-direction: column;
  }

  .btnWrapper button {
    min-width: unset;
  }
}

@media (max-width: 360px) {
  .btnWrapper button {
    max-width: 164px;
  }
}
