.footer {
  background-color: #0b486b;
  margin-top: auto;
}

.footerWrapper {
  max-width: 1600px;
  width: 100%;
  display: block;
  margin: 0px auto;
}

.footerContainer {
  padding: 40px 0px 31px 0px;
  display: flex;
  justify-content: space-between;
}

.footerHr {
  border: none;
  border-top: 1px solid #ffffff;
  margin: 0px;
}

.listItemLink {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.listItemLink svg {
  margin-right: 6px;
}

.listItemLink:hover {
  text-decoration: underline;
}

.footerContentRight {
  display: flex;
  align-items: center;
}

.logoImg,
.logoImg svg {
  width: 184px;
  height: 40px;
}

.contactsPosition,
.socialContainer {
  display: flex;
  align-items: center;
}

.socialContainer {
  width: 184px;
  height: 24px;
  justify-content: space-between;
  margin-right: 42px;
}

.socialContent a {
  display: flex;
  align-items: center;
  height: 24px;
}

.infoContainer {
  display: flex;
  align-items: center;
  height: 24px;
}

.infoContainer a {
  text-decoration: none;
  color: #ffffff;
  margin-right: 47px;
  margin-left: 15px;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.infoContainer:hover {
  text-decoration: underline;
}

.description {
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  display: block;
  margin: 0px auto;
  max-width: 1218px;
  width: 100%;
  padding: 23px 0px 32px 0px;
}

@media (max-width: 1655px) {
  .footerContainer {
    padding: 32px 24px 25px 24px;
  }
}

@media (max-width: 1500px) {
  .footerContainer {
    flex-direction: column-reverse;
    align-items: center;
    padding: 32px 16px 25px 16px;
  }

  .contactsPosition {
    border-bottom: 1px solid #ffffff;
    margin-bottom: 24px;
  }

  .footerContentRight,
  .contactsPosition {
    flex-direction: column-reverse;
    align-items: center;
  }

  .socialContainer {
    margin: 27px 0px;
    width: 248px;
  }

  .infoContainer {
    margin-bottom: 28px;
  }

  .infoContainer a {
    margin-right: 0px;
  }

  .description {
    padding: 23px 32px;
  }
}
