.alignCenter {
  display: flex;
  align-items: center;
}

.plusSvg {
  transform: scale(1.3);
}

.userHr {
  border: none;
  border-bottom: 1px solid #c5d7e0;
  margin: 3px 16px;
}
