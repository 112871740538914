.catalog {
  padding-bottom: 43px;
}

.catalogHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.catalogWrap {
  display: flex;
}

.catalogBooks {
  flex-grow: 1;
  max-width: 100%;
  margin-bottom: 64px;
}

@media (max-width: 639px) {
  .catalogHead {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}
