.card:hover .cardContent {
  opacity: 1;
  visibility: visible;
}

.cardImg {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.cardLink {
  display: flex;
  height: 100%;
  justify-content: center;
}

.cardLinkBorder {
  border: 1px solid #ccc;
}

.cardLink svg {
  margin-top: 15%;
}

.link {
  text-decoration: none;
}

.cardContent {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  background: rgba(0, 0, 0, 0.87);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.cardActions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardActionsVisible {
  position: relative;
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cardActionsVisible .cardActionsVisibleText {
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 6px;
}

.cardActionsVisibleBtn {
  position: absolute;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding-right: 0;
  cursor: pointer;
}

.cardActionsInfo {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cardActionsInfo .cardActionsInfoText {
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.cardActionsBtn {
  width: 131px;
}

.cardActionsBtn p {
  font-size: 14px;
  line-height: 16px;
  width: 131px;
}

.dropdown {
  padding: 24px 16px;
}

.dropdownInfo {
  margin-bottom: 24px;
}

.dropdownRow {
  display: flex;
  font-weight: normal;
  margin-bottom: 8px;
}

.dropdownRow p {
  font-size: 12px;
  line-height: 14px;
}

.dropdownRowName {
  width: 70px;
  margin-right: 4px;
  opacity: 0.4;
}

.dropdownRowVal {
  width: calc(100% - 55px);
}

.dropdownBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnPrice {
  margin-bottom: 16px;
  max-width: 156px;
}

.btnPrice {
  width: fit-content;
  margin-bottom: 16px;
  min-width: 156px;
}

.btnWishlist {
  width: fit-content;
  min-width: 156px;
}

.btnPrice p,
.btnWishlist p {
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}
