.borderWrapper {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px 24px;
  width: 100%;
  height: 100%;
}

.topSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  padding-bottom: 16px;
  margin-bottom: 22px;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.linkText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #0b486b;
  display: flex;
  align-items: center;
}

@media (max-width: 639.9px) {
  .borderWrapper {
    height: auto;
    border: none;
    background-color: #fafafa;
  }
}
